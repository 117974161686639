import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material Components
import { Grid, Paper } from '@mui/material';

import Dropdown from 'components/shared/Dropdown';
import TextBox from 'components/shared/TextBox';

import {
  EACHES,
  PACK_OZ_WEIGHT,
  VOLUMETRIC_UNITS,
} from 'lib/constants/compoundIngredientRecipeConstants';
import SectionHeader from '../shared/SectionHeader';

const ExtendedAttributes = ({ classes, formState, handleFormState, removeKeysFromFormState }) => {
  const { formOptions } = useSelector((state) => state.compoundIngredientRecipeForm.formOptions);
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [showPackSize, setShowPackSize] = useState(false);
  const [showAvgWeight, setShowAvgWeight] = useState(false);
  const [showVolumeToWeight, setShowVolumeToWeight] = useState(false);

  const volumetricPackUnits = useMemo(() => {
    return formOptions.packUnits
      .filter((unit) => {
        return VOLUMETRIC_UNITS.includes(unit.formattedName);
      })
      .map((unit) => unit.name);
  }, [formOptions.packUnits]);

  const selectOpsCategories = useMemo(() => {
    return formOptions.opsCategories.map((category) => ({
      label: category.formattedName,
      value: category.name,
    }));
  }, [formOptions.opsCategories]);

  const selectPantryCategories = useMemo(() => {
    return formOptions.pantryCategories.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  }, [formOptions.pantryCategories]);

  const selectPortionMethods = useMemo(() => {
    return formOptions.portionMethods.map((portionMethod) => ({
      label: portionMethod.formattedName,
      value: portionMethod.name,
    }));
  }, [formOptions.portionMethods]);

  const selectStandardUnits = useMemo(() => {
    const filteredStandardUnits = formOptions.recipeStandardUnits.filter(
      (unit) => unit !== 'eaches'
    );
    return filteredStandardUnits.map((standardUnit) => ({
      label: standardUnit,
      value: standardUnit,
    }));
  }, [formOptions.recipeStandardUnits]);

  const selectPackUnits = useMemo(() => {
    return formOptions.packUnits.map((packUnit) => ({
      label: packUnit.formattedName,
      value: packUnit.name,
    }));
  }, [formOptions.packUnits]);

  useEffect(() => {
    if (formState.standardUnit === EACHES) {
      setShowPackSize(true);
      // The avg weight input should not show up if the pack unit is `"pack_oz_weight"`
      if (
        formState.packUnit !== PACK_OZ_WEIGHT &&
        !volumetricPackUnits.includes(formState.packUnit)
      ) {
        setShowAvgWeight(true);
      } else {
        setShowAvgWeight(false);
      }
    } else if (formState.standardUnit !== EACHES) {
      setShowPackSize(false);
      setShowAvgWeight(false);
    }

    if (
      VOLUMETRIC_UNITS.includes(formState.standardUnit) ||
      volumetricPackUnits.includes(formState.packUnit)
    ) {
      setShowVolumeToWeight(true);
    } else {
      setShowVolumeToWeight(false);
    }

    setIsFirstRun(false);
  }, [formState.standardUnit, formState.packUnit, volumetricPackUnits]);

  // Makes sure hidden fields are emptied
  useEffect(() => {
    if (!isFirstRun) {
      const keysToRemove = [];
      if (!showPackSize) {
        keysToRemove.push('packSize', 'packUnit');
      }
      if (!showAvgWeight) {
        keysToRemove.push('avgWeight');
      }
      if (!showVolumeToWeight) {
        keysToRemove.push('volumeToWeight');
      }

      removeKeysFromFormState(keysToRemove);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPackSize, showAvgWeight, showVolumeToWeight]);

  return (
    <Paper className={classes.paper}>
      <SectionHeader text="Extended Attributes" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Dropdown
            label="Pantry Category"
            name="pantryCategoryId"
            onChange={handleFormState}
            options={selectPantryCategories}
            required
            value={formState.pantryCategoryId}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            label="Ops Category"
            name="opsCategory"
            onChange={handleFormState}
            options={selectOpsCategories}
            value={formState.opsCategory || undefined}
          />
        </Grid>
      </Grid>
      <TextBox
        disabled
        fullWidth
        helperText="Portion perishable ingredient on (# days before ship day)."
        // Leave blank if non-perishable.
        label="Perishable Days"
        name="perishableDays"
        onChange={handleFormState}
        type="number"
        value={formState.perishableDays}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextBox
            disabled
            endAdornment={<>&deg;F</>}
            fullWidth
            // helperText="Enter a number between -10 and 100."
            label="Min Storage Temp"
            name="minimumStorageTemperature"
            onChange={handleFormState}
            type="number"
            value={formState.minimumStorageTemperature}
          />
        </Grid>
        <Grid item xs={6}>
          <TextBox
            disabled
            endAdornment={<>&deg;F</>}
            fullWidth
            // helperText="Enter a number between 0 and 100."
            label="Max Storage Temp"
            name="maximumStorageTemperature"
            onChange={handleFormState}
            type="number"
            value={formState.maximumStorageTemperature}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Dropdown
            label="Portion Method"
            name="portionMethod"
            onChange={handleFormState}
            options={selectPortionMethods}
            value={formState.portionMethod}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            helperText="This unit is used for both culinary and operations."
            label="Standard Unit"
            name="standardUnit"
            onChange={handleFormState}
            options={selectStandardUnits}
            required
            value={formState.standardUnit}
          />
        </Grid>
      </Grid>
      {showPackSize && (
        <>
          <TextBox
            fullWidth
            helperText="REQUIRED if standard unit is 'Eaches'. Please enter 0 if no other value exits."
            label="Pack Size"
            name="packSize"
            onChange={handleFormState}
            required
            value={formState.packSize || ''}
          />
          <Dropdown
            helperText="This unit is used for both culinary and operations."
            label="Pack Unit"
            name="packUnit"
            onChange={handleFormState}
            options={selectPackUnits}
            value={formState.packUnit}
          />
        </>
      )}
      {showAvgWeight && (
        <TextBox
          fullWidth
          required
          helperText="REQUIRED if pack unit is 'None' or 'Eaches'. Please enter 0 if no other value exits."
          label="Average Weight (oz)"
          name="avgWeight"
          type="number"
          onChange={handleFormState}
          value={formState.avgWeight}
        />
      )}
      {showVolumeToWeight && formState.standardUnit === 'fluid_oz' && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextBox
              fullWidth
              helperText="REQUIRED: Conversion factor for converting the volume to oz weight per fluid oz. Example: 3.0"
              label="Volume to weight in fluid oz"
              name="volumeToWeight"
              onChange={handleFormState}
              required
              type="number"
              value={formState.volumeToWeight}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              fullWidth
              disabled
              label="Volume to weight in tbsp"
              type="number"
              value={formState.volumeToWeight ? formState.volumeToWeight / 2 : ''}
            />
          </Grid>
        </Grid>
      )}
      {showVolumeToWeight && formState.standardUnit === 'volume' && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextBox
              fullWidth
              disabled
              label="Volume to weight in fluid oz"
              type="number"
              value={formState.volumeToWeight ? formState.volumeToWeight * 2 : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              fullWidth
              helperText="REQUIRED: Conversion factor for converting the volume to oz weight per tbsp. Example: 3.0"
              label="Volume to weight in tbsp"
              name="volumeToWeight"
              onChange={handleFormState}
              required
              type="number"
              value={formState.volumeToWeight}
            />
          </Grid>
        </Grid>
      )}
      <TextBox
        fullWidth
        helperText="Enter a number between 0 and 1"
        label="Usage Yield"
        name="usageYield"
        onChange={handleFormState}
        required
        type="number"
        value={formState.usageYield}
      />
    </Paper>
  );
};

ExtendedAttributes.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
  removeKeysFromFormState: PropTypes.func.isRequired,
};

export default ExtendedAttributes;
